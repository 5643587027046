.dragInputDiv{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px dashed  #5999FF;
    background-color: #f4f7ff9f;
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.dragInputDiv input[type='button']{
   width: 220px;
   height:29px;
   background-color: #0073E6;
   border-radius: 20px;
   color: #ffffff;
   border: none;
}

.drag-container {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.questionContainer{
    padding: 15px 20px;
    background: #F8FDFF;
    border-radius: 5px;
    margin-bottom: 30px;
    width: 750px;
    border: 1px solid #CCCCCC;
    background-color: #fff;
}

.questionContainer .deleteIcon{
    width: 10px;
    float: right;
    cursor: pointer;
    /* position: absolute;
    top: 20;
    right: 20; */
}

.questionContainer .questionContainer_1{
    display: flex;
    align-items: center;
    width: 100%;
}

.questionContainer .questionContainer_1 .MoveBarIcon{
    width: 10px;
    margin-right: 10px;
}

.questionContainer .questionInnerContainer{
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #CCCCCC;
}

.questionContainer .questionInnerContainer .questionHeaderContainer{
    display: flex;
}

.questionContainer .questionInnerContainer .questionHeaderContainer img{
    width: 23px;
    height: fit-content;
    margin-left: 15px;
    cursor: pointer;
}
.questionContainer .questionInnerContainer .questionHeaderContainer .icon{
    width: 15px;
}

.questionContainer .questionInnerContainer textarea{
    background: transparent;
    border: none;
}

.questionContainer .questionInnerContainer .optionConatiner .optionInnerContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}

.questionContainer .questionInnerContainer .optionConatiner .optionInnerContainer input[type="radio"]{
    margin-top: 0px;
    cursor: pointer;
}

.questionContainer .questionInnerContainer .optionConatiner .optionInnerContainer input[type="text"]{
    background-color: transparent;
    border: none;
    outline: none;
    height: 25px;
}
.dateInput{
    height: 32px;
    border: none;
    border-radius: 5px;
    color: #818181;
    padding-left: 10px;

    .dateInputTitle{
        background-color: #f5f5f5;
        position: relative;
        font-size: 10px;
        margin-top: -7px;
        margin-left: 10px;
        width: fit-content;
        height: 10px;
    }
    
    input{
        font-size: 13px;
    }
    input::-webkit-calendar-picker-indicator {
        display: none;
        }

        
    
}

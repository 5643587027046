.contentPageMainContainer .topHeading {
    margin-left: 20px;
}

.unreadUpdatesCustomerCareContainer {
    font-size: 13;
    font-weight: 400;
}

.unreadUpdatesCustomerCareContainer span {
    background-color: #FA1A50;
    padding: 4px 8px;
    color: white;
    border-radius: 50%;
    margin-right: 4px;
}

.ccLables .lable {
    border-radius: 40px;
    padding: 5px 20px 5px 14px;
}
.ccLables .lable:hover{
    position: relative;
}
.ccLables .lable:not(:first-child) {
    margin-left: -18px;
}
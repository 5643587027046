.rightHandRightPageMainContainer .barIcon{
    background: #D9D9D9;
    width: 40px;
    padding: 9px;
    border-radius: 4px;
    cursor: pointer;
    height: fit-content;
}

.rightHandRightPageMainContainer .bulletIconMainContainer{
    display: flex;
}

.rightHandRightPageMainContainer .bulletIconContainer{
    background: #ffffff;
    width: fit-content;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px #00000040;
    border-radius: 5px;
    margin-left: 10px;
    position: absolute;
    z-index: 999;
}

.rightHandRightPageMainContainer .bulletIconContainer img{
    margin: 0px 10px 10px 0px;
    cursor: pointer;
    width: 32px;
    padding: 5px;
}

.rightHandRightPageMainContainer .bulletIconContainer img:hover{
    border: 1px solid lightgray;
}

.rightHandRightPageMainContainer .selectedBulletBoxIcon{
    margin-left: 10px;
    width: 22px;
}
.searchInputDiv{
  width: 100%;
  height: 32px;
}

.searchInputDiv input{
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  padding:5px 15px;
  color: #909090;
}

.searchInputDiv input:focus{
    border: 1px solid #c5c5c5;
  }

.searchInputDiv img{
  height: 10px;
  position: relative;
  top: -27px;
  right: calc(-100% + 20px);
}
.titleAndText{
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;

    .hoverChange:hover { 

      .showHover{
        display: flex;
        cursor: pointer;
      }
      
    .hideHover{
      display: none;
  }
  

    }

    .hideHover{
      cursor: pointer;
      width: 100%;
    }
    .showHover {
        display: none;
        cursor: pointer;
      width: 100%;
    }
    
    .showHover img{
       width: 20px;
    }
}

.detailedTicketInformationView {
    margin-top: 20px;
}

.detailedTicketInformationView .accordion-body{
    padding-bottom: 0px;
    padding-right: 0px;
}


.detailedTicketInformationView .createdByIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.detailedTicketInformationView .createdByIconContainer img {
    width: 40px;
}

.detailedTicketInformationView .agentsTouchedContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: auto;
}
.detailedTicketInformationView .agentsTouchedContainer img{
    width: 40px;
}
.detailedTicketInformationView .agentsTouchedContainer img:hover{
    z-index: 10;
}

.detailedTicketInformationView .agentsTouchedContainer img:not(:first-child) {
    margin-left: -20px;
  }


/* START : Overide style of basic grid component  */
.detailedTicketInformationView #detailedTicketInformationCollapseOne .tableFooter,
.detailedTicketInformationView #detailedTicketInformationCollapseOne .checkBoxCol {
    display: none;
}

.detailedTicketInformationView #detailedTicketInformationCollapseOne .main {
    margin-left: -31px;
    margin-bottom: 0px;
    width: calc(100% + 31px);
    overflow:visible;
}
.detailedTicketInformationView #detailedTicketInformationCollapseOne .tableBody{
    overflow:visible;
    height: auto;
}

.detailedTicketInformationView #detailedTicketInformationCollapseOne .showOnHover {
    padding: 30px 10px;
}

/* END : Overide style of basic grid component  */
.tagDiv .addBtn{
        display: none;
    }

.tagDiv:hover .addBtn{
        display: inline;
    }

.tagDiv .hideImage img{
    display: none;
}
.notesTextArea{
    width: 100%;
    border: 1px solid #ddd;
    overflow: hidden;
}

.CSVDisplay{
  margin-top: 20px;
  font-family: IBM Plex Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  
 
  img{
      height:15px;
  }
  .CSVDiv{
      background-color: #f8f9fa;
      border: 1px solid #CFD5DB;
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
  }
  .CSVTextArea{
      background-color: transparent;
      border: none;
      height: 300px;
      width:100%;
  }

}
.dynamicPageMainContainer{
    margin-left:240px;
    margin-top: 30px;
}

.articleDetails{
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-left: 18px;
}

.inputDiv{
  border-color: #ccc;
}

.selectAnImage{
  cursor: pointer;
}
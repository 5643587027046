
.phoneNumber{
    align-items: center;

    .numberView{
       flex-shrink: 1;
       margin-right: 3px;
       overflow: hidden;
       text-overflow: ellipsis;
    }

    .copyIcon{
      margin-left: auto;
    }
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup-form-input[type='text'], .popup-form-input[type='number']{
  height: 32px;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #252525;
  width: 100%;
}

.colorCheckbox{
  accent-color: black;
}

.coloredRadio:checked {
    background-color: black;
    border-color: black;
    accent-color: black;
}

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) for transparency */
  z-index: 9999999; /* Adjust the z-index as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popUpFooter{
   border-top: 1px solid #dfdfdf; 
   padding-top: 5px;
   display: flex;

  .cancelBtn{
    margin-left: auto;
    border-radius: 5px;
    color:#252525;
    background-color: #ffffff;
    border: none;
    width:75px;
    padding: 2px;
  }

  .saveBtn{
    border-radius: 5px;
    background-color: #252525;
    color:#ffffff;
    width:75px;
    padding: 2px;
  }
}

hr{
  background-color: transparent;
  color: transparent;
  border: 0px solid red;
  height:100px;
}

.icon-small{
  width:20px;
}

.select-new-drop-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 2rem;
  color: #252525;
}

.date-input{
  -webkit-appearance: none;
  -moz-appearance: none;
   height: 36px;
   border: 1px solid #dfdfdf;
   border-radius: 4px;
}

.time-input{
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 36px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.distBar{
  position: relative;
}

.distBar .circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.distBar .hide{
    display: none;
    
}


.distBar:hover .hide {
    /* display: absolute; */
    display: flex;
    background-color: white;
    padding:7px;
    position: absolute;
    top: -50%;
    left: 10%;
    z-index: 100;
    font-size: 12px;
    width: 90%;
    border: 1px solid #999;
    border-radius: 5px;
    color:#666
  }

.dynamicPageTabs{
    
    .tab-content{
      overflow: scroll;
    }
}

.offersPageMainContainer{
    margin: 0px 0px 0px 230px;
    padding-top: 30px;
}

.offersPageMainContainer>h4{
    margin-left: 20px;
}

 hr{
    margin-bottom: 0px;
    margin-top: 0px;
}

.tab{
    color: #FF6767;
    width: 130px;
    margin-left: 20px;
    text-align: center;
    margin-top: 30px;
}

.underline{
    background-color: #FF6767;
    height: 5px;
    border-radius: 10px 10px 0px 0px;
    margin-top: 5px;
}
.bottom-border{
    border-bottom: 1px solid black;
}

.offerActive{
    background-color: #39B54A;
    text-align: center;
    border-radius: 5px;
    width: 80px;
    color: #ffffff
}
.offerInactive{
    background-color: #FF0000;
    text-align: center;
    border-radius: 5px;
    width: 80px;
    color: #ffffff
}
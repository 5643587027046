.contentPageMainContainer{
    margin-left:230px !important;
    padding-top: 30px;
    background-color: #ffffff;
    
    height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;

    .tab-content{
        flex-grow:1;
        display: flex;
        flex-direction: column;

        .tabpanel{
            flex-grow:1;
            display: flex;
            flex-direction: column;
        }
        .active{
            flex-grow:1;
            display: flex;
            flex-direction: column;
        }
    }
}

.nav-tabs{
    border-bottom: 1px solid #000000;
}

.nav-tabs .nav-link{
    border: 0px;
    color: #252525;
}

.nav-tabs .nav-link.active {
    border-bottom: 4px solid #FF6767;
}

.contentPageMainContainer li{
    margin: 0px 20px;
}
.SeriesThumbnail{
    height: 80px;
    width: 80px;
    background-color: #ececec;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    img{
        height:10px;
    }
}


.seriesEdit .form-floating{

    width:90%

}

.blogTitle{
  border: none;
  font-size: 17px;
  font-weight:500;
  height:30px;
}
.blogDescription{
  border-radius: 5px;
  border: 1px solid #efefef;
}
.blogThumbnail{
    border-radius: 5px;
    border: 1px solid #efefef;

}

input::placeholder , textarea::placeholder{
    color: #bbb; /* Change the color to your desired color */
}

.quill{
    width:100%;
}
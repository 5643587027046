#topNavBar {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px;
  /* box-shadow: 0px 4px 25px 0px #00000040; */
  z-index: 311;
  height: 58px;
}


#innerSideNavbar {
  width: 230px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  min-height: 100%;
  padding-left: 2rem;
  padding-top: 3rem;
  border: 1px solid #e5e5e5;
}

#innerSideNavbar .navbar-nav {
  margin-top: 20px;
}

#innerSideNavbar .navbar-nav li{
  margin-top: 20px;
}

#innerSideNavbar .navbar-nav li a {
  color: #252525;
  text-decoration: none;
  font-weight: 400;
}

#innerSideNavbar .navbar-nav li img {
  margin-right: 1rem;
  width: 23px;
}

#innerSideNavbar .navbar-nav li .goBackArrow{
  margin-right: 0.9rem;
  width: 20px;
}

#innerSideNavbar .navbar-nav .componentBtn{
  border: 0px;
  background: transparent;
}

#innerSideNavbar span{
  color: #909090;
}

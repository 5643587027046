.searchInput{
  border-radius: 10px;
  width: 50%;
  border: 1px solid grey;
  margin-left: 10%;
  padding-left: 15px;
  padding-bottom: 2px;
}

@keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(5px);
    }
  }
  
  .alert {
    /* ... your existing styles ... */
    animation: shake 0.2s ease-in-out;
    color: rgb(6, 109, 14);
    border: 1px solid rgb(6, 109, 14);
    padding: fit-content;
    height: fit-content;
    width: 70px;
    margin:0%;
    margin-left: 15px;
    padding: 0%;
  }

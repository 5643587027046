#topNavBar {
  position: sticky;
  top: 0;
  background: white;
  padding: 10px;
  box-shadow: 0px 0px 6px 0px #00000040;
  z-index: 11;
}

#sidenavBar {
  width: 230px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  min-height: 100%;
  padding-left: 2rem;
  padding-top: 5rem;
  border-right: 1px solid #e5e5e5;
  overflow: scroll;
  height: 100%;
  scrollbar-width: 5px;
  padding-bottom: 2px;
  z-index: 200;
}

#sidenavBar::-webkit-scrollbar {
  display: none;
}#sidenavBar>div::-webkit-scrollbar {
  display: none;
}

#sidenavBar .navbar-nav {
  margin-top: 20px;
}

#sidenavBar .navbar-nav li{
  margin-top: 20px;
}

#sidenavBar .navbar-nav li a {
  color: #252525;
  text-decoration: none;
  font-weight: 400;
}

#sidenavBar .navbar-nav li img {
  margin-right: 1rem;
  width: 23px;
}

.activeTabIndicator{
  background-color: red;
  height: 30px;
  width: 4px;
  margin-right:20px;
}

::-webkit-scrollbar {
  width: 8px; /* Adjust as needed */
  height: 0px;
}

/* Style the thumb of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}

/* Style the track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: lightgray;
}
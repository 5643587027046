.documentVerificationCard{
    width: 326px;
    height: 326px;
    background-color: #f1f1f1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    position: relative;
    cursor: pointer;
    
    .pageCount{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 43px;
        height: 18.81px;
        border-radius: 14px;
        background-color: #252525;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        font-size: 13px;
      }
    
    .cardBody{
      background-color:'red';  
      flex-grow: 1; 
      overflow: scroll;    

      .cardImages{
        display: flex;
        min-height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .pdfContainer{

      }
    }
    .cardBody::-webkit-scrollbar{
        width: 8px;
        height:8px;
        position: absolute; /* Position the scrollbar absolutely */
    right: 0; /* Align the scrollbar to the right */
    top: 0; /* Align the scrollbar to the top */
    z-index: 1; /* Ensure scrollbar appears above content */
    }
    .cardBody::-webkit-scrollbar-track{
        background-color: transparent;
    }

        
    .cardFooter{
        background-color: #0073E6;
        width: 337px;
        height: 42px;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        font-family: Inter;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;

    }
    .cardFooter input[type="checkbox"]{
        height: 25px;
        width: 25px;
    }

    .clickableDiv{
        width: 337px;
        height: 337px;
        background-color: none;
        margin-top: -60px;
    }

    .clickableDiv:active {
        background-color: #f1f1f1; 
    }
}


.zoomControls{
    position: absolute;
    top: 10px;
    left: 10px;
    display: inline-flex;
    gap: 3px;

    div{
      height: 24px;
      width: 24px;
      border-radius: 20px;
      overflow: hidden;
      background-color: #FFFFFF80;
      border: 1px solid #FFFFFF80;
      box-shadow: 0px 0px 4px 0px #00000026;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:hover{
        cursor: pointer;
    }
    div:active{
        transform: translate(2px, 1px);
        background-color: #FFFFFF;
    }

    input{
        width: 40px;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
        font-size: 13px;
    }
}
.courseFreeOptionsContainer{
    margin-left: 20px;
    margin-top: 10px;   
    display: none;
}

.disablePricingIfCourseIsFree{
    color:#BBB;
}

.disablePricingIfCourseIsFree input[type="text"],
.disablePricingIfCourseIsFree input[type="number"]{
    border: 1px solid #BBB;
    color:#BBB;
    background: transparent;
}

.pricingPageReginPara{
    margin-bottom: 6px;
     margin-top: 14px
}

.coursePricingInputs{
    border: 1px solid #BBB;
}

.pricingPageReginPara{
    font-size: 16px;
   font-weight: 600;
   color:#000000;
}



.seriesStatusPopUp{
    width:299px;
    font-family: Inter;
    padding: 12px;
    font-size: 13px;
    gap:5px;
    display: flex;
    flex-direction: column;
    
}

.seriesStatusPopUp span{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;  
}

.seriesSelect{
  width: 100%;
  background-color: #f2f2f2;
  color: #b4b4b4;
  border: 1px solid #e4e4e4;
}

hr{
    margin-top: 2px;
    margin-bottom: 2px;
}

.cancleBtn{
  border:1px solid white;
}

.myAlert{
  background-color: cornsilk;
  color: red;
  width: 100%;
  border: 1px solid red;
  padding:2px;
  border-radius: 10px;
}
.AdminPageMainContainer{

    .chartTab{
        display:flex;
        flex-direction:column;
        flex:1;
        border:#ccc;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: #F9F9F9;
        cursor :pointer;
        color: #646464;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: center;

        b{
            font-size: 18px;
            font-weight: 400;
            color:#252525;

        }
    }

    .chartTabSelected{
        border-bottom: none!important;
        background: #ffffff!important;

    }

    .filterSelect{
        width: 150px;
        flex: none;
    input{
        background-color: transparent;
        border: none;
        font-size: 15px;
        color: #757575;
        width: 93%;
        font-weight: 400;
        text-align: left;
        margin-top: 0px;
    }

    img{
        height: 14px;
        cursor: pointer;
    }

    .dateInputTitle{
        background-color: #ffffff;
    }
    }

}

.funnelChild{
    flex:1;
    height:'100%';
    width:'100%';
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    gap: 3px;
}

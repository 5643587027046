.emailViewer{
  border: 1px solid #BDBDBD;
  background-color:  #F2F6FC;
  height: 100%;
  overflow-x: scroll;
  border-radius: 5px;
  padding : 10px;
}

.value{
  background-color: 'red';
  border-radius: 10;
  font-weight: 700;
}

.mailVariable{
  background-color: #fff;
  border: 1px solid #0066ff;
  padding: 0px 10px;
  border-radius: 15px;
  color: #0066ff;
  cursor: pointer;
  font-size: 12px;
}
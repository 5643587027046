.ticketActivityView{
    margin-top: 20px;
    margin-bottom: 20px;
}

.ticketActivityView .ticketActivityDataAccordion{
    margin-bottom: 20px;
}

.ticketActivityView .ticketActivityDataAccordion .taActivityAccordion_0{
  background-color: red;
}

.ticketActivityView .accordionActivityBtn{
    display: flex;
    justify-content: space-between !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #CCCCCC !important;
}

.ticketActivityView .accordionActivityBtn:not(.collapsed)::after{
    right: -5px;
    margin-top: -2px;
}
.ticketActivityView .accordionActivityBtn::after{
    right: -5px;
}

/* .ticketActivityView .conversionsAccordion{
    margin-bottom: 25px;
} */

.ticketActivityView .accordionConversionsBtn{
    display: flex;
    justify-content: space-between;
    font-size: 15px !important;
    font-weight: 500 !important;
    color : #252525 !important;
    z-index: 12 !important;
}

.ticketActivityView .accordionConversionsBtnHiddenIcon::after{
    display: none;
}

.ticketActivityView .accordionConversionsBtn .s1{
    display: flex;
    align-items: center;
}
.ticketActivityView .accordionConversionsBtn .s1 .profileIcon{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    z-index: 10;
    background-color: #CCCCCC;
}

.ticketActivityView .accordionConversionsBtn:not(.collapsed)::after{
    right: -5px;
    margin-top: -2px;
}
.ticketActivityView .accordionConversionsBtn::after{
    right: -5px;
}

.ticketActivityView .accordionActivityBody{
    position: relative;
}
.ticketActivityView .accordionConversionsLine{
    position: absolute;
    width: 1.7px;
    height: 74%;
    background-color: #E4E4E4;
    left: 52px;
    z-index: 9;
}
.ticketActivityView .accordionConversionsBody{
    padding: 1rem 3.25rem;
    font-size: 14px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #252525;
    padding-right: 0px;

}

.ticketActivityView .accordionConversionsBody .lastSeenDiv{
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.31px;
    text-align: left;
    color: #A1A1A1;
    display: flex;
    gap: 5px;
    justify-content: end;
    align-items: center;

}
.ticketActivityView .accordionConversionsBody .lastSeenDiv img{
    height: 20px;
}

.ticketActivityView .withoutLineHelper{
    margin-bottom: 25px;
}

.ticketActivityView .withLineHelper{
    position: relative;
    z-index: 12;
}

.ticketActivityView .accordionLineHelperContainer{
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: white;
    height: 30px;
}
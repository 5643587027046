.bssForm{
    width: 16cm;
    padding: 1.3cm 1.6cm;
    font-family: Arial;
    
    line-height: 1.1;
    background-color: #ffffff;
    .bssHeader{
       width: 100%;
       display: flex;
       justify-content: space-between;
       align-items: start; 

       .icon{
        height: 2cm;
       }
    }

    .bssHeader:nth-child(1){
        /* flex: 0.7; */
    }

    .passportPhoto{
        height: 110px;
        width: 86px;
        min-width: 86px;
        border: 0.5px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 7px;
        background-position: center center;
        background-size: cover;
        overflow: hidden;
    }
    .passportPhoto img{
        height: 100%;
        width:100%;
    }

    table{
        display: table;
        border-color: grey;
        table-layout:fixed;
    }
    
    td{
        font-size: 9px;
        line-height: 1.1;
        padding: 5px ;
        border-color: grey;
        border-top: 0.5px;
        /* word-wrap:break-word; */
        text-wrap:wrap;
        /* word-break: break-all; */
    }
    tr{
        display: table-row;
        border-color: grey;
    }

    tr:nth-child(1)>td{
      border-top: 1px solid grey;
    }
    
    tr td:nth-child(2) {
        border-right: 0px;
        font-family: 'Times New Roman';
        font-size: 11px;
        font-weight: 700;
    }
    tr td:nth-child(1) {
        width:30%;
        padding-right:8px;
        padding-left:10px;
        text-overflow: wrap;
    }
    table td, table td * {
        vertical-align: top;
    }

    .fillInBlank{
        display: flex;
        gap: 10px;
        flex: 0.3;
    }
    .fillInBlank>span{
        flex-grow: 1;
        border-bottom: 1px solid grey;
        font-family: 'Times New Roman';
        /* font-size: 11px; */
        font-weight: 700;
        justify-content: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
    }

    .studentIdFiled{
        font-size: 14px;
        text-transform: uppercase;
        font-family: 'Times New Roman';
        display: flex;
        gap: 10px;
    }
    .studentIdFiled div:nth-child(2){
        flex:1;
    }
    .studentIdFiled>div:nth-child(1){
        width: 113px;
    }

    .studentIdFiled .underlineBlank{
        border-bottom: 1px solid grey;
        min-height: 16px;
        padding-left: 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .charBox{
        height: 20px!important;
        width:20px!important;
        border: 1px solid grey;
        flex: none!important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tickOption{
        height: 20px;
        width:fit-content;
        padding-left: 5px;
        padding-right: 5px;
        border: 0.1px solid black;
        font-family: Arial;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: 46px;
    }
    .tick::before{
        content: url('@assets/common/checkSmall.png');
        margin-top: 3px;
    }

    .signature{
        width: 100px;
        height: 40px;
    }
}


.timesFont{
font-family: 'Times New Roman';
}

.calibriFont{
    font-family: sans-serif;
}

.arialFont{
    font-family:Arial;
}
.imageInputDiv{
    background-color: #ffffff;
    font-family: Inter;
    width:743px;
    box-shadow: 0px 0px 10px #eeeeee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.imageInputDiv .imageInputHeader{
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  border-bottom: 0.5px solid #E5E5E5;
  justify-content: center;
  align-items: center;
}

.imageInputDiv .imageInputHeader div{
    font-size: 15px;
    font-weight: 600;      
    color:#252525;
}
.imageInputDiv .imageInputHeader img{
    margin-left: auto;
    height: 10px;
  }
.imageInputDiv .imageInputHeader img:active{
    transform: translate(0px, 2px);
  }

.imageInputDiv .imageInputBody{
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  gap:10px;
  width: 100%;
}

.imageInputDiv .search{
    display: flex;
  } 
 
.imageInputDiv .search input{
    padding-left: 20px;
    background-color: #F5F5F5;
    border: none;
    border-radius: 25px;
    width: 100%;
    font-size: 13px;
    height: 32px;
    font-weight: 400;
}  
.imageInputDiv .search img{
    position: relative;
    right:30px;
    top:10px;
    height: 10px;
  }  

.imageInputDiv .imageInputFooter{
    border-top: 0.5px solid #E5E5E5;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 30px;
    justify-content: flex-end;
    align-items: center;
}
.imageInputDiv .imageInputFooter input{
   font-size: 13px;
   padding: 5px 16px;
   /* width: 120px;
   height:29px; */
   background-color: #0073E6;
   border-radius: 20px;
   color: #ffffff;
   border:none;
   margin-right: 15px;
}

.imageInputDiv .imageInputFooter input:disabled{
  background-color: #aaaaaa;
  cursor:no-drop;
}

.imageInputDiv .imageInputBody .thumbnailDiv{
  display: flex;
  gap:20px;
  flex-wrap: wrap;

  height: 290px;
  overflow-y: scroll;  
}
.imageInputDiv .imageInputBody .thumbnailDiv .thumbnail{
  height: 105px;
  width: 115px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #E5E5E5;
  cursor: pointer;
}

.imageInputDiv .imageInputBody .thumbnailDiv .closeIcon{
  height: 10px;
  position: relative;
  top:0px;
  left :80px;
  cursor: pointer;
}

.imageInputDiv .imageInputBody .thumbnailDiv .selectedAwsImg{
  border: 2px solid blue;
  padding: 4px;
  border-radius: 5px;  
}

.imageInputDiv .imageInputBody .thumbnailDiv .centerDiv{
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.imageInputDiv .imageInputBody .thumbnailDiv .centerDiv img{
  width: 50px;
}

.imageInputDiv .imageInputBody .paginationContainer{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.imageInputDiv .imageInputBody .paginationContainer button{
  background: transparent;
  border: 0px;
  background-color: #0073E6;
  color: white;
  border-radius: 7px;
  padding: 2px 8px;  
}
.imageInputDiv .imageInputBody .paginationContainer .paginationNumbersText{
  margin-left: 10px;
  margin-right: 10px;
}
.imageInputDiv .imageInputBody .paginationContainer .paginationTotalText{
  font-size: 14px;
}
.examPageBgHolder {
    background: #EBF4F8;
    padding: 3rem 0rem;
    height: 75vh;
}

.examPageMainContainer {
    margin: 0rem 20vw;
    max-width: 1340px;
    text-align: left;
    font-family: Inter;
    
    
}

.examPageHeadingContainer {
    padding: 1rem;
    background-color: white;
    border-top: 14px solid #4D80CD;
    border-radius: 10px;
    font-family: Inter;
font-size: 33px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;

}

.examPageHeadingContainer .pointers {
    display: flex;
}

.examPageHeadingContainer .pointers p {
    margin-bottom: 10px;
}

.examPageHeadingContainer .pointers img {
    height: fit-content;
    margin-right: 8px;
    width: 20px;
}

.examPageMainContainer #startExamBtnContainer {
    text-align: center;
    margin-top: 25px;
}

.examPageMainContainer #startExamBtn {
    border: 0px;
    background-color: #4D80CD;
    border-radius: 10px;
    padding: 6px 120px;
    color: white;
}


.examPageMainContainer .questionContainer {
    padding: 1rem 1rem 0rem 1rem;
    background-color: white;
    border: 1px solid #252525;
    border-radius: 5px;
    margin-bottom: 25px;
    

}

.examPageMainContainer .questionContainer .question {
    margin-top: 10px;
    margin-bottom: 1.3rem;
    
}

.examPageMainContainer .questionContainer .option {
    margin-bottom: 20px;
}

.examPageMainContainer .pageNavigationBtn {
    display: flex;
    justify-content: space-between;
}

.examPageMainContainer .pageNavigationBtn img {
    width: 25px;
    height: fit-content;
    cursor: pointer;
    height: 23px;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer {
    text-align: right;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer p {
    margin-bottom: 2px;
    color: red;
    font-size: 0.9rem;
}

.examPageMainContainer .pageNavigationBtn .submitExamContainer button {
    color: white;
    border: 0px;
    padding: 4px 80px;
    border-radius: 7px;
    background: #252525;
}



.examDurationContainer {
    position: sticky;
    top: 80px;
    border-radius: 5px;
    background: #4D80CD;
    color: white;
    margin: 0px 0px 15px 0px;
    align-items: center;
    z-index: 9999;
}

.examDurationContainer .duration{
    font-size: 30px;
}

.strikeContainer,
.durationContainer{
    padding: 10px;
}

.examDurationContainer .examStrikeNoteContainer {
    text-align: right;
    font-size: 10px;
    margin-top: 5px;
}

.durationContainer{
    border-left: 1px solid white;
}


/* .examPageMainContainer .pageNavigationBtn img{
    width: 20px;
    height: fit-content;
    cursor: pointer;
} */


@media (max-width: 1340px) {
    .examPageMainContainer {
        margin: 0rem 5rem;
    }
}

@media (max-width: 768px) {
    .examPageMainContainer {
        margin: 0rem 1rem;
    }
    .examDurationContainer .duration{
        font-size: 1rem;
    }    
    .durationContainer{
        border-left: none;
    }
}

.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer{
    display: flex;
    font-size: 0.8rem;
}

.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer .leftContent{
    min-width: 120px;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.examPageBgHolder .examPageHeadingContainer .resultMatrixContainer .rightContent{
    color: white;
    border-radius: 7px;
    min-width: 70px;
    text-align: center;
}


.examPageMainContainer .resultPageQuestionContainer{
    padding: 1rem 1rem 0rem 1rem;
    background-color: white;
    border: 1px solid #252525;
    border-radius: 5px;
    margin-bottom: 25px;
    
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.examPageMainContainer .resultPageQuestionContainer .question{
    margin-top: 10px;
    margin-bottom: 1.3rem;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer{
    width: 92px;
    border: 0px;
    background: #FFE9E9;
    padding: 5px 6px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 5px;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer p{
    margin-bottom: 0px;
    font-size: 0.8rem;
}

.examPageMainContainer .resultPageQuestionContainer .missingBadgeContainer img{
    width: 17px;
}

.examPageMainContainer .resultPageQuestionContainer .option{
    padding: 10px;
}


.examPageMainContainer .resultPageQuestionContainer .resultRadioBtn,
.examPageMainContainer .resultPageQuestionContainer .resultRadioBtnLabel{
    opacity: 1 !important;
    margin-left: 0px;
    margin-right: 10px;
}

.examPageMainContainer .resultPageQuestionContainer .selectedRadioBtn input{
    background: black;
    border: 0px;
}

.examPageMainContainer .resultPageQuestionContainer .answerIsCorrect{
    background: #E6FFE5;
}

.examPageMainContainer .resultPageQuestionContainer .answerIsInCorrect{
    background: #FFE5E5;
}  

.examPageMainContainer .resultPageQuestionContainer .rightAnswerIcon{
    float: right;
    width: 18px;
    margin-top: 5px;
    margin-right: 10px;
}

.examPageMainContainer .resultPageQuestionContainer .wrongAnswerIcon{
    float: right;
    width: 15px;
    margin-top: 3px;
    margin-right: 10px;
}


.searchTop{
    position: fixed;
    top: 12px;
    left: 50%;
    z-index: 100;
    border-radius: 17px;
    border: none;
    background-color: #F5F5F5;
    width: 575px;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, 0);
    font-family: Inter;

    .searchMain{
      display: flex;
      height: 34px;
      justify-content: center;
      align-items: center; 
      gap: 10px; 
      padding: 0px 20px;
    }
    input[type=text]{
        background-color: transparent;
        border: none;
        font-size: 13px;
        color: #757575;
        width: 93%;
        font-weight: 400;
        text-align: left;
        margin-top: 3px;
    }
    input[type=date]{
        background-color: transparent;
        border: none;
        font-size: 13px;
        color: #757575;
        width: 93%;
        font-weight: 400;
        text-align: left;
        margin-top: 3px;
    }

    img{
        height: 14px;
        cursor: pointer;
    }

    .searchFilters{
      border-top: 1px solid #e5e5e5;
      padding: 10px 20px 10px 20px;
      
      .filterSelect, .filterSelectDate{
        flex: 0.33;
        height: 32px;
        border: 1px solid #818181;
        border-radius: 5px;
        color: #818181;
        padding: 0px;
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      .filterSelect{
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        >img{
          height: 7px
        }
      }
    }

    
}
.rightHandRightPageMainContainer .displayIconContainer{
    display: flex;
}

.rightHandRightPageMainContainer .tab-content{
    flex-grow: 1;
    overflow: scroll;
    padding-bottom: 10%;
}



.rightHandRightPageMainContainer .displayIconContainer .displayIconInnerContainer{
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 18px;
    justify-content: space-between;
}

.rightHandRightPageMainContainer .displayIconContainer img{
    display: block;
    width: 30px;
    margin-bottom: 9px;
}

.rightHandRightPageMainContainer .displayIconContainer label{
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.rightHandRightPageMainContainer .displayIconContainer input[type="checkbox"]{
    display: block;
    font-size: 18px;
    margin-top: 10px;
    accent-color: black;
}

.lefSideCardContainer{
   display: flex;
   justify-content: center;
}

.boxStyleCheckBox{
    font-size: 18px;
    border-radius: unset !important;
    border: 1px solid;
    font-size: 15px;
}
.mainLoginPageContainer{
    background: rgb(240, 238, 238);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92vh;
}

.mainLoginPageContainer .loginContainer{
    width: 499px;
    background: white;
    padding: 30px 50px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px grey;
}

.mainLoginPageContainer .loginContainer input[type="text"],
.mainLoginPageContainer .loginContainer input[type="password"]{
    border-radius: 10px;
    border: 1px solid #252525;
    padding: 13px;
}

.mainLoginPageContainer .loginContainer .loginBtn{
    border-radius: 15px;
    background: #000;
    color: white;
    border: 0px;
    width: 100%;
    padding: 8px;
    font-size: 18px;
}


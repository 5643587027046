.form-floating span{
    color: red;
}
.form-floating textarea:hover{
    border-color: #454545;
}

.colorPicSection{
    display: flex;
    width: 213px;
    height: 43px;
}

.colorInfo{
    height: calc(3.5rem + 2px);
    flex: 0.8;
    padding: .75rem .75rem;
    display: flex;
    /* justify-content: center; */
    gap:5px;
    align-items: center;

}

.colorPicker{
    flex: 0.2;
    height: calc(3.5rem + 2px);
    padding: .75rem .75rem;
    line-height: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorInfo .colorPicked{
    height: 20px;
    width: 20px;
    border-radius: 2px;
}

.greyBorder{
    border: 1px solid #CCCCCC;
    border-radius: 5px;
}

.colorPicker img{
    height: 30px;
}

.colorInfo input{
  border: none;   
}

.displayIconContainer input[type='checkbox']{
    height:18px;
    width:18px;
    accent-color: black;
}
.displayIconContainer input[type='checkbox']:checked{
    height:15px;
    width:15px;
    accent-color: black;
    background-color: black;
}

.imageCarousel .containBg{
    background-size: contain;
}
.basicInfoView{
    margin-top: 20px;
    margin-bottom: 20px;
}
.basicInfoView .userDetialsTable{
    border: 1px solid #E5E5E5;
    /* border: 1px solid black; */
    border-radius: 10px;
}
.basicInfoView .userDetialsTable .rowX{
    --bs-gutter-x: 0;
}
.basicInfoView .userDetialsTable .rowBorderX{
    border: 1px solid #E5E5E5;
    /* border: 1px solid black; */
    border-width: 0px 0px 1px 0px;
}
.basicInfoView .userDetialsTable .userDetialsTableRightContainer,
.basicInfoView .userDetialsTable .userDetialsTableLeftContainer{
    padding: 10px;    
}
.basicInfoView .userDetialsTable .userDetialsTableRightContainer{
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: #E5E5E5;
    /* border-color: black;  */
    color: #898989;
}
.mainContainer{
    margin-left: 250px;
    padding-top: 25px;
    display: flex;  
    flex-wrap: wrap; 
    gap: 4%;
    padding-bottom: 10%;
}

.leftContainer{
    flex: 0.55;
}

.rightContainer{
    flex: 0.25;
}

.inputDiv{
    height: 55px;
    width: 98% !important;
    border: 0.5px solid rgb(165, 165, 165);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 0.5% 2%;
    margin: 1%;
}
.large{
    height: 130px;
}
.inputDiv:focus-within{
    border: 0.5px solid rgb(53, 168, 177);
    box-shadow: 0px 0px 3px rgb(0, 247, 255);
}

.inputDiv>.label{
    font-size: 11px;
    color: #606060;
}

.inputDiv>input[type="text"], .inputDiv>textarea, .inputDiv>input[type="number"]{
    border: none;
    padding: 0px;
}
.inputDiv>input[type="text"]:focus, .inputDiv>textarea:focus, .inputDiv>input[type="number"]{
    border: none;
    outline: none;
}

.inputDiv>textarea{
    resize: none;
    flex-grow: 1;
}

.bottomLabel{
    font-size: 15px;
    color: #606060;
    text-align: end;
}

.dropDown{
    height: 55px;
    width: 100%;
    border: 0.5px solid rgb(165, 165, 165);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 0.5% 2%;
    margin-top: 1%;
    background-color: #F9F9F9;
    align-items: center;
    justify-content: space-between;

}

.dropDown img{
    height: 10px;
    width: 20px;
    justify-self: end;
}

.couponDiv{
    display: flex;
    margin: 1%;
    gap:2%;
}

.couponDivLeft{
    flex:0.9;
}

.couponDivRight{
    flex:0.1;
}

.couponDiv>div>div{
   justify-content: center;
}

.infoContainer{
    background-color: #F9F9F9;
    color: #8F8F8F;
    display: flex;
    align-items: center;
    padding: 10px;
    gap:15px;
}
.infoContainer>img{
    height: 20px;
}

.optionDiv{
    display: flex;  
    gap:5%;  
}

.optionDiv>div{
    flex:0.5;
    background-color: #F9F9F9;
    padding: 2% 5%;
    border: 0.5px solid #CBCBCB;
}
.optionDiv>div>b{
    margin-bottom: 10px;
}

.buttonDiv{
    display: flex;
    justify-content: flex-end;
    gap: 2%;
}

.buttonLight{
    width: 120px;
    height: 33px;
    background-color: #dadada;
    color:#252525;
    border: 1px solid #dadada;
    border-radius: 10px;
}

.buttonDark{
    width: 120px;
    height: 33px;
    background-color: #252525;
    color:#dadada;
    border: 1px solid #252525;
    border-radius: 10px;
}

.wideButton{
    width: 100%;
    height: 33px;
    background-color: #252525;
    color:#dadada;
    border: 1px solid #252525;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 5%;
    align-items: center;
}

.wideButton>img{
    height:20px;
}

.coverPicDiv{
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 80%;
    background-color: #F0F0F0;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 10px;
    text-align: center;
}

.coverPicInnerDiv{
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:auto;
    background-color: #FE5D91;
    height: 80%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: inherit;
}

.coverPicInnerDiv>p{
    padding: 4%;
    text-align: justify;
    color: #FFFFFF;
    width: inherit;
    word-break: break-all;
}

.progress{
    height: 40px;
    width:100%;
    background: linear-gradient(93.95deg, #B36D1A -23.18%, #FFD35F -2.51%, #F7D49E 16.44%, #F9EFBC 33.67%, #E5B256 76.74%, #FFCF56 104.3%, #FBD268 121.53%, #B36D1A 142.2%);

}

.selectList{

}

.selectionList{
   margin-top: 10px;
   height: 150px;
   overflow-y: scroll;
}


/* Styling the scrollbar thumb */
.selectionList::-webkit-scrollbar-thumb {
background-color: #ccc;
border-radius: 10px;
}

/* Styling the scrollbar track */
.selectionList::-webkit-scrollbar-track {
background-color: #f1f1f1;
}

/* Optional: Hide the default scrollbar on WebKit browsers */
.selectionList::-webkit-scrollbar {
width: 8px;
height: 0px;
}

/* Optional: Hide the default scrollbar on Firefox */
.selectionList {
scrollbar-width: thin;
scrollbar-color: #ccc #f1f1f1;
}

.selectionList>div{
    height:auto;
    display: flex;
    gap: 10px;
    padding: 5px 15px;
}

.selectionList>div:hover{
    background-color: #F3F3F3
}
    
input[type="radio"]{
   color: #252525;
}

.searchDiv{
    justify-content: center;
    flex:0.9
}
.searchDiv img{
  margin-left: auto;
  height: 20px;
  width: 20px;
  margin-top: -20px;
}

.selectListFooter{
 display: flex;
 justify-content: end;
 padding-right: 20px;
 padding-top: 10px;
}
.selectListFooter>span{
 color: #2063D2;
 font-weight: 600;
}
.selectListFooter>span:hover{
 text-shadow: 0px 0px 2px #2063D2;
 cursor: pointer;
}

.dropDown>span{
    width: 90%;
    height: 30px;
    overflow: hidden;
}


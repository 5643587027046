.SeriesThumbnail{
    height: 80px;
    width: 80px;
    background-color: #ececec;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    img{
        height:10px;
    }
}


.seriesEdit .form-floating{

    width:90%

}


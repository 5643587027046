.qualificationPopUpDiv{
    width: 694px;
    height: auto;
    font-family: Inter;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.qualificationPopUpDiv > .hover:hover{
    background-color: #f4f4f4;
    
}
.qualificationPopUpDiv > .hover > span{
    background-color: red;
    width: 43px;
    padding: 1px 10px 1px 10px;
    border-radius: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;font-family: Inter;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qualificationPopUpDiv > .hover > img{
    height: 8px;
}

.qualificationPopUpDiv > .hover{
   
}
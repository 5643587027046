.form-floating input{
    border: 0.5px solid #CCCCCC
}
.form-floating input:hover{
    border: 0.5px solid #444444
}

.form-floating > label{
  color:#9f9f9f;
  font-size: 14px;
}

.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe;
  width: 300px;
  height: 200px;
  font-size: 13px;
  overflow: scroll;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 240px;
}
 
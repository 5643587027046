.tabs{
  display: flex;
  gap: 20px;
  margin-left: 2px;
  padding-left: 10px;
  border-bottom: 3px solid #E5E5E5;
  font-size: 17px;
  color: #252525;
  overflow-x: scroll;
  position: relative;


}

.tabs>div:hover{
    cursor: pointer;
   border-bottom: 3px solid #6f7070;
}

.tabs>div{
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;    
    text-wrap: nowrap;
}

.selected{
   border-bottom: 3px solid #0E9AFF;
}

.titleTag1{
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  padding : 2px 6px;
  border-radius: 14px;
}

.tabsContainer{
  position: relative;

  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    height: 22px;
    background-color: white;
    border: none;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%;
    box-shadow: 0px 0px 5px grey;
  }
  
  .scroll-button.left {
    left: 0;
    
  }
  
  .scroll-button.right {
    right: 0;
  }
}
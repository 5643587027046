.BSSPageMainContainer{
    margin: 0px 0px 0px 230px;
    padding-top: 20px;
    
    height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;
}

.BSSPageMainContainer>h4{
    margin-left: 20px;
}

.signature{
    width:100%;
    aspect-ratio: 16/6;
}

.fix{
    
    .tab-content{
        /* display: flex; */
        flex: 1;
        overflow: hidden;
        
        .tab-pane{
            height:100%;
            overflow: hidden;
        }
    }
}

.date{
    background-color: #FF9533;
    color: white;
    padding: 4px 7px;
    border-radius: 4px;
  }
  

  .image-container {
    position: relative;
    display: inline-block;
    .image {
        width: 25px; /* Adjust as needed */
        height: auto; /* Adjust as needed */
    }
    .image:hover + .username {
        display: block;
    }
    .username {
        position: absolute;
        top: 32px; /* Adjust as needed */
        left: -30px;
        border: 1px solid #BCBCBC;
        color: #FFAE4F;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        background-color: #FFF4CD;

        display: none;
    }.username:hover {
        display: block;
    }
    
}

  
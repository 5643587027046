.userPurchasesMainContainer .userPurchasesAccContainer .accordion-button {
    padding: 0px;
}

.userPurchasesMainContainer .userPurchasesAccContainer .accordion-button::after {
    position: absolute;
    left: 8px;
}

.userPurchasesMainContainer .userPurchasesAccContainer .accordion-button.collapsed::after{
    transform: rotate(270deg);

}

.userPurchasesMainContainer .userPurchasesAccContainer .accordion-button:not(.collapsed)::after{
    transform: rotate(360deg);
}

.userPurchasesMainContainer .userPurchasesAccContainer .outerTable{
    margin-bottom: 0px;
    border-collapse: collapse;
    border: transparent;
}

.userPurchasesMainContainer .userPurchasesAccContainer .outerTable th{
    border: 1px solid #E5E5E5;
    border-width: 1px;
    padding-left: 35px;
    text-align: left;
    border-top: none;
}

.userPurchasesMainContainer .userPurchasesAccContainer .innerTable tr:nth-child(even) {
    background-color: #F5F5F5;
  }

.userPurchasesMainContainer tr{
    display: table-row;
}

.userPurchasesMainContainer .userPurchasesAccContainer .headerP{
    margin-bottom: 5px;
    font-weight: bold;
}
.userPurchasesMainContainer .userPurchasesAccContainer .valueP{
    font-weight: 400;
    margin-bottom: 0px;
}

.userPurchasesMainContainer .userPurchasesAccContainer .accordion-button:not(.collapsed){
    color: black;
    background-color: white;
}

.userPurchasesMainContainer .userPurchasesAccContainer .userPurchasesACollapseContainer{
    background: #F5F5F5;
}

/* Add some margin between elements within a td */
td > * {
    margin-right: 10px;
  }
.userPurchasesMainContainer .userPurchasesACollapseContainer .accordion-body{
    padding: 0px;
}


.userPurchasesMainContainer .userPurchasesAccContainer .innerTable{
    border-collapse: collapse;
    border: none;
    margin-top: -2px;
}


.userPurchasesMainContainer .userPurchasesAccContainer .innerTable th{
    font-weight: 400;
    border: 1px solid #E5E5E5;
    padding-left: 13px;
    text-align: left;
}



.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .courseCardPlaceHolder{
    width: 140px;
}

.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .statusTh,
.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .examStatusTh{
    text-align: center;
    vertical-align: middle;
}

.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .examStatusTh .passed{
    background-color: #39B54A;
    border-radius: 5px;
    padding: 3px 10px;
    color: white;
}

.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .statusTh .dropDownArrowIcon,
.userPurchasesMainContainer .userPurchasesAccContainer .innerTable .examStatusTh .dropDownArrowIcon{
    width: 19px;
    margin-left: 15px;
    cursor: pointer;
}

.inputPercentageContainer{
    display: inline-block;
    margin-left: 9px;
}

.inputPercentageContainer input[type='number']{
    border-width: 1px 0px 1px 1px;
    width: 70px;
}

.inputPercentagSign{
    border: 1px solid;
    border-width: 1px 1px 1px 0px;
    padding: 2.5px 5px 2px 0px;
}

.showResultsContainer{
    display: inline-block;
    margin-left: 9px;
}
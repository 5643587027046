.editTitleMain{
    width: 400px;
    height: 300px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.editTitleMain .uploadDiv{
    border: 1px solid #f2f2f2;
    
    font-size: 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 10px;
}

.editTitleMain .uploadDiv img{
    height: 15px;
    width:15px;
}

.editTitleMain input{
    height: 32px;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    color: #252525;
    width: 100%;
}


.blackBtn{
  background-color: #252525;
  color:white;
  
 border: 1px solid #252525;
}


.greyFont{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #B4B4B4;
}

.closeBtn{
    border-radius: 8px;
    background: none;
    border: 1px solid white;
    padding: 1px 25px;
}
.closeBtn:hover{
    border: 0.5px solid #252525;
}

.hr{
    height:1px;
    width:100%;
    background-color: #E6E6E6;
    border: none;
}

.uploadDiv{
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}
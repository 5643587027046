.BSSPageMainContainer{
    margin: 0px 0px 0px 230px;
    padding-top: 20px;
    height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;
}

.BSSPageMainContainer>h4{
    margin-left: 20px;
}

.signature{
    width:100%;
    aspect-ratio: 16/6;
}
.bssDateOfFormInput{
    width: 100%;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding-left: 5px;
    padding-right: 5px;
}
.faqQuestionContainer{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    padding: 15px 15px;
}

.faqQuestionContainer:hover{
    box-shadow: none;
    background: #F5F5F5;
}
.faqQuestionContainer:hover .faqQuestionInput {
    background: transparent;
  }

.faqQuestionContainer .faqHeadContainer{
    display: flex;
    justify-content: space-between;
    margin-left: 28px;
}

.faqQuestionContainer .deleteIcon{
    height: fit-content;
}

.faqQuestionContainer .faqQuestionInput{
    border: 0px;
    margin-bottom: 10px;
    padding-left: 0px;
}


.faqQuestionContainer .faqAnswerInput{
    border-radius: 5px;
    border: 1px solid #ccc;
    background: #FFF;
}
/* .commentsAccordion .accordion-button{
    padding: 7px 12px;
    border-radius: 10px;
    font-size: 0.7rem;
    width: fit-content;
    color: #0963e4;
    padding-left: 25px;
}

.commentsAccordion .accordion-button::after {
    position: absolute;
    background-size: 7px;
    left: 8px;
    height: 12px;
    width: 10px;
    background-image: url('../../assets/comments/commentsClose.png');
}

.commentsAccordion .accordion-button:not(.collapsed)::after{
    transform: rotate(90deg);
} */

.commentsAccordion .accordion-body{
    padding: 10px 0px;
}

.moveToDiv{
  font-size: 13px;
}

.moveToDiv .searchDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:5px;
    padding: 10px;
}

.moveToDiv .searchDiv input[type = 'checkbox']{
  border: 1px solid #EfEfEf ;
}
    
.moveToDiv .searchDiv .searchInput{
 flex: 0.95;
 flex-grow: 1;
 padding: 0%;
 display: flex;
 overflow: hidden;
 align-items: center;
 padding:5px 10px;
 border: 1px solid #E5E5E5;
 margin-left: 0px;
 color: #909090;

}

.moveToDiv .searchDiv .searchInput input{
  border: none;
  flex: 0.95
}

.moveToDiv .searchDiv .searchInput img{
 margin: 0%;
 padding: 5px;
}

.moveToDiv .folderOption{
  display: flex;
  padding: 5px;
  gap:15px;
  padding-left: 20px;
}

.moveToDiv .folderOption:hover{
   background-color: #E5E5E5;    
}
.moveToDiv .folderOption img{
  height: 18px;
}

.automaticTags{
    height:40px;
    width:137px;
    border: 1px solid #C1C1C1;
    background-color: #F1F1F1;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.tagsDiv{
    width:1000px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    
}

.tagsDiv input[type='checkbox']{
    height:18px;
    width:18px;
    accent-color: black;
}

.automaticTags>span{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.visibilityContainer  form-check-input{
    font-size: 14px;
}

.sectionSelect{
    display: flex;
    gap: 5px;
    align-items: center;
    width: 40%;
}

.sectionSelect input[type='checkbox']{
    height:14px;
    width:14px;
}
.sectionSelect label{
   font-weight: 500;
   font-size: 16px;
}
.visibilityContainer input[type='radio']:checked{
    background-color: black;
    border-color: black;
}

.imageCarousel{
    display: flex;
    gap:15px;
    flex-wrap: wrap;
}

.imageCarousel>div{
    height: 132px;
    width: 110px;
    border-radius: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.imageCarousel .carouselImage{
    background-color: #f2f2f2;
}

.imageCarousel .carouselImage> .closeIcon{
    position: relative;
    top:0px;
    left:calc(100% - 20px);
    height: 10px;
  }
.imageCarousel .carouselImage> .closeIcon:active{
    transform: translateY(2px);
}

.imageCarousel .addCarouselImage{
  border: 1px dashed #CCCCCC;
  background-size: 18px;
}

.courseDetails .inputTitle{
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    color:#252525
}
.courseDetails .inputSubTitle{
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    color: #848484;

}

.inputSmall{
    border: 0.5px solid #CCCCCC;
    padding: 1rem 0.74rem;
    width:100px;
}
.inputSmall:hover{
    border: 0.5px solid #444444
}

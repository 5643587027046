.addCourseMain{
    width: 460px;
    height: auto;
    font-family: Inter;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.addCourseMain>span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
}
.courseSelector select{
    border: 1px solid #E6E6E6;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #B4B4B4;
    flex: 0 0 40%;
}

.greyBg{
  background-color: #F2F2F2;
}

.blackBtn{
  background-color: #252525;
  color:white;
  
 border: 1px solid #252525;
}

.paymentInfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:5px;
}

.paymentInfo>*{
    flex: 0 0 49%;
    border: 1px solid #E6E6E6;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #B4B4B4;
}

.greyFont{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #B4B4B4;
}

.closeBtn{
    border-radius: 8px;
    background: none;
    border: 1px solid white;
    padding: 1px 25px;
}
.closeBtn:hover{
    border: 0.5px solid #252525;
}

.hr{
    height:1px;
    width:100%;
    background-color: #E6E6E6;
    border: none;
}

.newCourseContainer{
    display: flex;
    gap: 5px;
    align-items: center;
}

.newCourseContainer .courseThumbnail{
    width: 140px;
}

.newCourseContainer .courseName{
    /* margin-left: 10px; */
    flex: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #252525;

}

.newCourseContainer .blackCrossIcon{
    float: right;
    width: 13px;
    height: 13px;
    cursor: pointer;
}
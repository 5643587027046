.rightHandRightPageMainContainer .videoPage_CommentsPage_FilterContainer{
    margin : 18px 5px 18px 14px;
}

.videoPage_CommentsPage_FilterContainer .sortIcon{
    width: 25px;
    margin-right: 26px;
    cursor: pointer;
}

.videoPage_CommentsPage_FilterContainer .filterBtns, 
.col-commentActionContainer .commentActionBtn{
    border-radius: 15px;
    border: 1px solid #000;
    background: #F8F8F8;
    width: 140px;
    height: 33px;
}

.videoPage_CommentsPage_FilterContainer .filterBtns{
    margin-right: 20px;
}

.col-commentActionContainer .commentActionBtn:hover{
    background-color: white;
}

.videoPage_CommentsPage_FilterContainer .selectedFilter{
    border-radius: 15px;
    background: #F8F8F8;
    border: 0px;
    color: #0882F3;
}

.videoPage_CommentsPage_FilterContainer .sortContainer,
.allComments_FilterContainer .sortContainer{
    background: white;
    position: absolute;
    width: 110px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 25px 0px #00000040;
}

.allComments_FilterContainer .sortContainer{
    margin-top: 130px;
}

.videoPage_CommentsPage_FilterContainer .sortContainer span,
.allComments_FilterContainer .sortContainer span{
    cursor: pointer;
    font-size: 14px;
}


/* START : allCommentsContainer */
.allCommentsContainer{
    border: 1px solid;
    border-width: 0px 0px 1px 0px;
}

.allCommentsContainer:hover{
    background: #EBEBEB;
}

.allCommentsContainer  .col-profileImgPlaceholder{
    width: 29px;    
    padding-left: 0px;
}

.allCommentsContainer .col-commentContainer{
    width: 55%;    
}

.allCommentsContainer .commenterName{
    margin-bottom: 1px;
    font-size: 1rem;
}

.allCommentsContainer .commentTime{
    font-size: 0.8rem;
    color: #CECECE;
    margin-left: 10px;
}

.allCommentsContainer .comment{
    margin-bottom: 6px;
    font-size: 0.9rem;
}
.allCommentsContainer .commentInteractionContainer{
    display: flex;
    font-size: 0.7rem;
    /* height: 25px; */
    align-items: center;
}
.allCommentsContainer .commentLikeContainer{
    margin-right: 11px;
    margin-bottom: 0px;
}

.allCommentsContainer .commentLikeContainer i{
    margin-right: 4px;
    cursor: pointer;
}

.allCommentsContainer .commentDislikeContainer{
    margin-right: 15px;
    cursor: pointer;
    margin-bottom: 0px;    
}

.allCommentsContainer .commentReplyBtn{
    background: transparent;
    border: 0px;
    height: fit-content;
    margin-right: 3px;
    font-size: 12px;
}

.allCommentsContainer .commentReplyBtn:hover{
    color: grey;
}

.allCommentsContainer .favIcon{
    width: 15px;
    height: fit-content;
    margin-right: 15px;
    cursor: pointer;
}

.allCommentsContainer .shareIcon{
    width: 15px;
    height: fit-content;
    cursor: pointer;
}

.allCommentsContainer .col-commentActionContainer{
    width: 42%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
/* END : allCommentsContainer */


/* START : accordion-button */
.commentInteractionContainer .accordion-button{
    padding: 7px 12px;
    border-radius: 10px;
    font-size: 12px;
    width: fit-content;
    background: transparent;
    color: black;    
    margin-right: 7px;
}


.commentInteractionContainer .accordion-button::after {
    background-size: 12px;
    left: 8px;
    height: 12px;
    width: 14px;
    margin-left: 7px;
    margin-top: 1px;    
    background-image: url('@assets/comments/commentsClose.png');
}

.commentInteractionContainer .accordion-button:not(.collapsed)::after{
    transform: rotate(180deg);
}
/* START : accordion-button */



/* START : userCommentContainer */
.userCommentContainer .col-userCommentInput{
    width: 94%;    
}

.userCommentContainer input{
    font-size: 14px;
    border: 1px solid #C6C6C6;
    border-radius: 5px;
    padding: 5px;
}

.userCommentContainer .CCBtnContainer{
    width: 100%;
    justify-content: flex-end;
}

.userCommentContainer .cancleBtn,
.userCommentContainer .continueBtn{
    width: 100px;
    margin-right: 15px;
    background: transparent;
    border: 0.5px solid #bfbcbc;
    border-radius: 30px;
    padding: 5px 0px;
    font-size: 0.8rem;    
}

.userCommentContainer .cancleBtn:hover{
    background:#ECECEC
}


.userCommentContainer .continueBtnAddOn{
    background: #065db1 !important;
    color: white;
}


.userCommentContainer .continueBtnAddOn:hover{
    background: #083f8b !important;
    color: white;
}
/* END : userCommentContainer */



.rightHandRightPageMainContainer .allComments_FilterContainer{
    margin: 7px 5px 7px 14px;
    display: flex;
    align-items: center;
}


.allComments_FilterContainer .sortIcon{
    width: 25px;
    margin-right: 26px;
    cursor: pointer;
    height: fit-content;
}

.allComments_FilterContainer .inputDateContainer{
    margin-right: 20px;
}

.allComments_FilterContainer .inputDateContainer input[type="date"]{
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px;
}

commentvideoduration {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }
  

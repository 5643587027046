.button-bar-container{
  display: flex;
  overflow-x: scroll;
  /* justify-content: center; */
  align-items: center;

  .scroll-button{
    width: 20px;
    height: 35px;
    padding: 5px;
    border: 1px solid #ccc;
  }
}
.button-bar{
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  font-size: 13px;
  overflow-x: scroll;
}

.button-bar button:active, .dropdown-content div:active{
    transform: translateY(2px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.roundBtn{
 /* background-color: #1573FE; */
 border-radius: 50%;
 height: 32px;
 width:32px;
 border: 0px solid #1573FE;
 background-position: center center;
 background-repeat: no-repeat;
 background-size: contain;
}

.simpleBtn{
  min-width: 152px;
  max-width: fit-content;
  border: 1px solid #1967D2;
  color: #1573FE;
  background-color: #ffffff;
  border-radius: 5px;
  height: 32px;
  display: flex;
  align-items:center;
  justify-content: center;
  font-size: 13px;
  gap: 5px;
}

.simpleBtn .startIcon{
  height: 16px;
}
.simpleBtn .endIcon{
  height:7px;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.addNumberMain{
    width: 230px;
    height: auto;
    font-family: Inter;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.addNumberMain>span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    
}
.numberSelector select{
    border: 1px solid #E6E6E6;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #252525;
    width: 40%;
    
}

.numberSelector input{
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #252525;
    width: 60%;
}


.blackBtn{
  background-color: #252525;
  color:white;
  
 border: 1px solid #252525;
}


.greyFont{
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #B4B4B4;
}

.closeBtn{
    border-radius: 8px;
    background: none;
    border: 1px solid white;
    padding: 1px 25px;
}
.closeBtn:hover{
    border: 0.5px solid #252525;
}

.hr{
    height:1px;
    width:100%;
    background-color: #E6E6E6;
    border: none;
}

#productCard {
    width: 320px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 0px 15px grey;
    height:fit-content;
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 0;
    /* 16:9 Aspect Ratio */
}

.productImgContainer{
    width: 100%;
    overflow: hidden;
    height: auto;
    border-radius: 15px;
}
.productCardDetails{
    padding: 17px;
    font-size: 16px;
}
.productCardDetailsInfo{
   display: flex;
   flex-direction: column;
   width:95%
}
.subPointers{
  margin-top:3px
}

.empty-image {
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  /* background-color: rgba(224, 224, 224, 0.8); */
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(-45deg, #ffffff, #a5a5a5); */
  animation: gradientChange 3s infinite alternate;
}


@keyframes gradientChange {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.subPointers p{
    margin: 0px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }
  
.pointer img{
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }

.productCardIcon{
  width: 20px;
  height: auto;
}

  .pointer span{
    margin-left: 5px;
  }

.productStaticIcons img{
    width: 60%;
}
.productStaticIcons {
    font-size: 10px;
    padding: 15px 10px;
    border-top: 1px solid #bebebe ;
    border-bottom: 1px solid #bebebe ;

}
.productStaticIcons span {
    margin-top: 10px;
}

.timeDisplay{
    display: flex;
    align-items: end;
    color: #007185;
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 0px 0px 7px 0px;
}

.productStaticIcons .col-2{
    padding: 0px;
}

.bulletSpan{
    font-weight: 600;
    font-size: 16px;
    color: #525252;
    display: flex;
}
.bulletSpan div{
    height: 13px;
    width: 13px;
    background-color: navy;
    margin-top: 5px;
    border-radius: 50%;
    flex:none;
}
.productCardbottom{
  padding: 5px 0px;
}

.card-button{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    font-size: 26px;
    font-weight: 700;
    margin-top: 5px;
 }
 .yellowButton{
     background-color: #ffd814;
 }
 .greenButton{
     background-color: #039800;
     color: white;
     font-weight: 300;
     font-size: 22px;
 }
 .blackButton{
     background-color: #252525;
     color: #FFE645;
     font-weight: 400;
 }

 .strikediag {
    padding:0px;
    margin: 0px;
    background: linear-gradient(to left top, transparent 47.75%, red 49.5%, red 50.5%, transparent 52.25%);
    height: fit-content;
  }

  .darkButton{
    height: 28px;
    color:white;
    font-size: 16px;
    font-weight: 400;
    background-color: #252525;
  }

  #courseContentDescriptionContainer ul {
    list-style-type: none;
    padding-left: 0px;
}

#courseContentDescriptionContainer li {
    margin-bottom: 5px;
}

#courseContentDescriptionContainer li:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: -3px;
}

#courseContentDescriptionContainer .infoIcon{
    position: absolute;
    top: 0px;
    padding: 10px;
    right: 0px;
    width: 45px;
    display: none;
}
.userMainPage .users_FilterContainer select{
    width: 120px;
    margin-top: 20px;
    border: 1px solid #252525;
    margin-right: 15px;
}


.userMainPage .users_FilterContainer .searchFilterInputBox{
    margin-top: 18px
}
.userMainPage .users_FilterContainer .searchFilterInputBox .form-control {
    padding-left: 2.375rem;
    border: 1px solid #000000;
    border-radius: 19px;
}

.userMainPage .users_FilterContainer .searchFilterInputBox .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}


.userMainPage .users_FilterContainer .excelIconContainer{
    margin-top: 20px;
    cursor: pointer;
    position: absolute;
    right: 20px;
}


.userMainPage .Xcontainer_3{
    align-items: center;
}

.userMainPage .profilePictureContainer img{
    width: 50px;
}

.userMainPage .Xcontainer_2 .sortAccCreated,
.userMainPage .Xcontainer_2 .sortDatePurchase{
    cursor: pointer;
}

.userMainPage .Xcontainer_2 .sortAccCreated p,
.userMainPage .Xcontainer_2 .sortDatePurchase p{
    display: flex;
    align-items: center;
}


.userMainPage .Xcontainer_3 .visibilityPopupContainer .inputPercentageContainer,
.userMainPage .Xcontainer_3 .visibilityPopupContainer .showResultsContainer{
    display: inline-block;
    margin-left: 9px;
}

.userMainPage .Xcontainer_3 .visibilityPopupContainer .inputPercentageContainer input[type='number']{
    border-width: 1px 0px 1px 1px;
    width: 70px;
}

.userMainPage .Xcontainer_3 .visibilityPopupContainer .inputPercentageContainer span{
    border: 1px solid;
    border-width: 1px 1px 1px 0px;
    padding: 2.5px 5px 2px 0px;
}

.controlsRight{
    margin-right: 10px;
    font-size: 13px;
    align-content: center;
}

.controlsRight button{
    color: #818181;
    background-color: transparent; 
    border: none;
}

.controlsRight button:active{
    transform: translateY(2px);
}

.controlsRight img{
    height: 13px;
    width: auto;
}

.settingOption{
    width: 180px;
    padding: 10px;
    font-size: 13px;

}
.settingOption:hover, .createUserDiv>div:hover{
    background-color: #e5e5e5;
    cursor: pointer;
}
.settingOption:active, .createUserDiv>div:active{
    transform: translateY(2px);
}

.profileImg{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #e5e5e5;
}

.createUserDiv > div{
  padding: 5px 15px;
  display: flex;
  gap:10px;
  align-items: center;
  font-size: 13px;
}
.newFont{
 font-size: 13px;
 color: #252525;
}

.popUpHeading{
    font-family: Inter;
font-size: 17px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;

}
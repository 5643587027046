/* Common css */
.rightHandRightPageMainContainer{
    margin-left:230px;
    padding-top: 30px;
    /* max-width: 986px; */
    height: calc(100vh - 58px)

}

.rightHandRightPageMainContainer .marginLeftContainer{
    margin-left: 20px;
    margin-right: 10px;
}

.rightHandRightPageMainContainer .topContainer{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 30px;
    width: 800px;
    
}

.rightHandRightPageMainContainer .heading{
    margin-bottom: 10px;    
    font-size: 1.3rem;
}

.rightHandRightPageMainContainer .bulletPointsContainer{
    align-items: end;
}

.rightHandRightPageMainContainer .bulletPointsContainer .bulletPointsInputContainer{
    width: 95%;
    margin-right: 0px;
}

.rightHandRightPageMainContainer .bulletPointsContainer .bulletPointsInputContainer input[type="text"]{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #cccccc;
}

.rightHandRightPageMainContainer .bulletPointsContainer .bulletPointsIconContainer{
    width: 5%;
    top: -20px;
    position: relative;
    cursor: pointer;
}

.rightHandRightPageMainContainer .bulletPointsContainer .bulletPointsIconContainer img{
    width: 5%;
    width: 25px;
}


.rightHandRightPageMainContainer .subHeading{
    font-size: 18px;    
    margin-bottom: 10px;    
}


.rightHandRightPageMainContainer .topContainer .cancelBtn, 
.rightHandRightPageMainContainer .topContainer2 .cancelBtn{
    margin-right: 15px;
    background: transparent;
    border: 0px;
    color: #535353;
    border-radius: 8px;
    padding: 2px 12px;
}

.rightHandRightPageMainContainer .topContainer .cancelBtn:hover,
.rightHandRightPageMainContainer .topContainer2 .cancelBtn:hover{
    border: 0.5px solid lightgray;
}

.rightHandRightPageMainContainer .topContainer .saveBtn,
.rightHandRightPageMainContainer .topContainer2 .saveBtn{
    background: #252525;
    color: white;
    border: 0px;
    border-radius: 8px;
    padding: 2px 12px;
}

.rightHandRightPageMainContainer .inputBox{
    padding: 9px;
    border-radius: 5px;
    border: 1px solid #ccc;
}


/* Common css */

.rightHandRightPageMainContainer .infoNote{
    border-radius: 5px;
    background: #F9F9F9;
    padding: 12px 5px 12px 12px;
    color: #8F8F8F;
    font-size: 14px;
    margin-top: 15px;
}

.rightHandRightPageMainContainer .infoNote img{
    width: 23px;
    margin-right: 10px;    
}



.rightHandRightPageMainContainer .selectCourseContainer{
    border-radius: 5px;
    border: 0.5px solid #252525;
    background: #F9F9F9;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;    
}

.rightHandRightPageMainContainer .selectCourseContainer p{
    margin-bottom: 0px;
}

.rightHandRightPageMainContainer .addCourseContainer{
    background: white;
    position: absolute;
    margin-top: -30px;
    width: 398px;
    border-radius: 5px;
    box-shadow: 0px 4px 25px 0px #00000040;
}

.rightHandRightPageMainContainer .addCourseContainer input[type="text"]{
    border-width: 1px 0px 1px 1px;
}

.rightHandRightPageMainContainer .addCourseContainer .searchBtn{
    border: 1px solid #cdd4da;
    border-width: 1px 1px 1px 0px;
}

.rightHandRightPageMainContainer .addCourseContainer .searchedCourseListContainer{
    padding: 10px;
}

.rightHandRightPageMainContainer .addCourseContainer .searchedCourseListContainer label{
    cursor: pointer;
}

.rightHandRightPageMainContainer .addCourseContainer .doneBtn{
    float: right;
    color: gray;
    border: 0px;
    background: transparent;
    margin: 0px 10px 10px 0px;
}



.rightHandRightPageMainContainer .visibilityContainer{
    background: white;
    width: fit-content;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    padding: 20px 270px 20px 25px;
}

.rightHandRightPageMainContainer .incompleteFieldsError{
    padding: 10px;
   margin-bottom: 20px;
   border-radius: 10px;
   border: 1px solid;
   background-color: #f8d7da;
   color: #842029;
}


/* .shakeError {
    animation: shakeError 1s ease-in-out;
}
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
} */

.absolutePos{
    position: fixed!important;
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filterSelect, .filterSelectDate{
    flex: 0.33;
    height: 32px;
    border: 1px solid #818181;
    border-radius: 5px;
    color: #818181;
    padding: 0px;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
  .filterSelect{
    justify-content: space-around;
    >img{
      height: 7px
    }
  }
.form-floating input{
    border: 0.5px solid #CCCCCC
}
.form-floating input:hover{
    border: 0.5px solid #444444
}

.form-floating > label{
  color:#9f9f9f;
  font-size: 14px;
}

.halfSizeInput{
  .form-control{
    height: calc(2rem + 2px);
    padding-top: 1.3rem;
  }
  .form-control:not(:placeholder-shown){
    padding-top: 1.3rem;

  }
  label{
    font-size: 12px;
    padding: .4rem;
  }
}

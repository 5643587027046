input[type='checkbox'] {
    cursor: pointer;
}

.Xcontainer_1 {
    margin-top: 12px;
    margin-left: 20px;
}

.Xcontainer_1 .createXBtn,
.Xcontainer_1 .visiblityXBtn {
    font-size: 14px;
    background: transparent;
    border: 0.5px solid #B8B8B8;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.Xcontainer_1 .createXBtn img {
    width: 12px;
    margin-right: 5px;
}

.Xcontainer_1 .createXBtna{
    color: black;
    text-decoration: none;
    padding: 2px 7px;    
}

.Xcontainer_1 .visiblityXBtn img {
    width: 12px;
    margin-left: 5px;
}

.Xcontainer_1 .deleteXBtn {
    background: transparent;
    border: 0px;
    cursor: pointer;
}

.Xcontainer_1 .deleteXBtn img {
    width: 16px;
}

.Xcontainer_2 {
    margin-left: 20px;
    justify-content: space-evenly;
    /* justify-content: space-between; */
}


.Xcontainer_2 .arrowSort {
    display: flex;
}

.Xcontainer_2 .arrowSort p {
    margin-bottom: 0px;
    margin-right: 5px;
    cursor: pointer;
}

.Xcontainer_2 .arrowSort img {
    width: 9px;
    height: fit-content;
    margin-top: 7px;
}

.Xcontainer_3_internal {
    justify-content: space-evenly;
    /* justify-content: space-between; */
    padding-left: 42px;
    margin-bottom: 18px;
    align-items: center;
}

.Xcontainer_3_internal:hover {
    background-color: #E6E6E6;
}

.Xcontainer_3_internal .thumbnailContainer {
    cursor: pointer;
}

.Xcontainer_3_internal .editIconsContainer p {
    margin-bottom: 0px;
}

.Xcontainer_3_internal .editIconsContainer img {
    width: 20px;
    margin-right: 15px;
    cursor: pointer;
}

.Xcontainer_3_internal .editIconsContainer .hiddenDiv {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
}

.Xcontainer_3_internal:hover .hiddenDiv {
    visibility: visible;
    opacity: 1;
}

.Xcontainer_3_internal .visibility {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Xcontainer_3_internal .visibility img {
    width: 17px;
    height: fit-content;
    margin-right: 5px;
}

.Xcontainer_3_internal .activeUsersContainer,
.Xcontainer_3_internal .totalViews,
.Xcontainer_3_internal .totalComments {
    display: flex;
}

.Xcontainer_3_internal .activeUsersContainer img,
.Xcontainer_3_internal .totalViews img,
.Xcontainer_3_internal .totalComments img{
    width: 17px;
    height: fit-content;
    margin-right: 5px;
    margin-top: 5px;
}




.visibilityPopupContainer {
    background: white;
    width: 300px;
    padding: 15px;
    box-shadow: 0px 4px 25px 0px #00000040;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
}

.thubmnailPopupContainer{
    background: white;
    width: 380px;
    padding: 15px;
    box-shadow: 0px 4px 25px 0px #00000040;
    border-radius: 5px;
    position: absolute;
    /* display: none; */
    z-index: 10;
}

.visibilityPopupContainer .visibilityPopupContainer_inner,
.thubmnailPopupContainer .thubmnailPopupContainer_inner {
    padding: 15px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.thubmnailPopupContainer .alert-danger{
    margin: 15px 0px;
    padding: 10px;
    border-radius: 5px;
}

.visibilityPopupContainer .visibilityPopupContainer_inner p
.thubmnailPopupContainer .thubmnailPopupContainer_inner p {
    margin-bottom: 10px;
}

.visibilityPopupContainer .cancleBtn,
.thubmnailPopupContainer .cancleBtn {
    border-radius: 8px;
    background: transparent;
    border: 0px;
    padding: 1px 25px;
    margin-right: 10px;
}

.visibilityPopupContainer .cancleBtn:hover,
.thubmnailPopupContainer .cancleBtn:hover{
    border: 1px solid #9d9a9a;

}

.visibilityPopupContainer .saveBtn,
.thubmnailPopupContainer .saveBtn{
    border-radius: 8px;
    background: #252525;
    color: white;
    border: 0px;
    padding: 1px 25px;
}


.thubmnailPopupContainer .thubmnailPopupContainer_inner{
    display: flex;
    padding: 0px;
    height: 100px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.thubmnailPopupContainer .thubmnailPopupContainer_inner img{
    width: 20px;
    height: fit-content;
    margin-right: 10px;
    cursor: pointer;
}



.pageNavigationBtn {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    max-width: 95%;
}

.pageNavigationBtn img {
    width: 30px;
    height: fit-content;
    cursor: pointer;
}

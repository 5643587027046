.rightHandRightPageMainContainer .examNameContainer{
    margin-bottom: 18px;
}

.rightHandRightPageMainContainer .examNameContainer,
.rightHandRightPageMainContainer .noOfQuestionsContainer{
    display: flex;
    align-items: center;
}

.rightHandRightPageMainContainer .examNameContainer input[type="text"],
.rightHandRightPageMainContainer .noOfQuestionsContainer input[type="number"]{
    padding: 7px;
}

.rightHandRightPageMainContainer .noOfQuestionsContainer input[type="number"]{
    width: 10%;
}




.rightHandRightPageMainContainer .maxDurationContainer{
    display: flex;
    align-items: center;
}

.rightHandRightPageMainContainer .maxDurationContainer input[type="number"]{
    width: 30%;
    margin-right: 20px;
}

.rightHandRightPageMainContainer .maxDurationContainer img{
    width: 40px;
    height: fit-content;
}


.rightHandRightPageMainContainer .examPointerParaContainer{
    margin-bottom:10px;
    display: flex;
    align-items: center;
}

.rightHandRightPageMainContainer .examPointerParaContainer h4{
    margin-bottom: 0px;
    margin-right:  10px;
}

.rightHandRightPageMainContainer .addQuestionBtn{
    background: black;
    border: 0px;
    border-radius: 10px;
    color: white;
    padding: 4px 40px;
}

.rightHandRightPageMainContainer .addQuestionBtn .roundedWhitePlusIcon{
    width: 18px;
    margin-right: 10px;
    margin-top: -3px;
}
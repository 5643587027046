.userProfileView .userProfileImgMainContainer{
    border: 1px solid #BDBDBD;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.userProfileView .userProfileImgMainContainer .deleteIcon{
    width: 15px;
    top: 10px;
    position: absolute;
    right: 9px;
    height: fit-content;
}

.userProfileView .userProfileImgMainContainer .uploadIcon{
    border-radius: 100px;
    width: 130px;
    height: 130px;
}

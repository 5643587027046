.bgDiv{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, 0.575)
}
.verificationCard{
    display: flex;
    position:fixed;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    padding: 1%;

    background-color:white;
    flex-direction: column;
    box-shadow: 0px 0px 5px #858484;

}
.verificationCard>div{
    overflow: scroll;
}
.verificationCard>div::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
.backIcon{
    width: 1%;
    cursor: pointer;
}

.imageHolder{
    background-color: #D68C8C;
    padding: 1%;
    overflow: scroll;    
}

.imageHolder input[type='checkbox']{
    height: 25px;
    width: 25px;
    position: fixed;
    top:10%;
    z-index: 100;
}

.imageHolder>img{
    position: relative;
    left: 50%;
    transform: translate(-40%, 0%);
    overflow: scroll;
}

.verificationCard>div>div{
    flex: 0.5;
}

.imageHolder::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

.imageHolder::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

.imageHolder::-webkit-scrollbar-thumb {
    background: rgb(136, 136, 136);
  }

.imageHolder::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
  }

.imageHolder::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
  }
.verificationCard span{
    height: 30px;
    display: inline-flex;
  align-items: center;
}
.userPageMainContainer .userProfileImgMainContainer{
    position: relative;
    border: 1px solid #BDBDBD;
    height: 270px;
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.userPageMainContainer .userProfileImgMainContainer .deleteIcon{
    width: 15px;
    top: 10px;
    position: absolute;
    right: 9px;
    height: fit-content;
}

.userPageMainContainer .userProfileImgMainContainer .uploadIcon{
    border-radius: 100px;
    width: 180px;
    height: 180px;
}

.userPageMainContainer .userProfileContentMainContainer .formMainContainer {
    border: 1px solid #BDBDBD;
    padding: 0px 0px;
    border-radius: 10px;
    overflow: hidden;
}

.formRow{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px; 
    border-bottom: 1px solid #BDBDBD;
}

.formRow>p{
    padding-left: 1%;
}

.formMainContainer>.formRow:last-child{
    border-bottom: none;
}

.userPageMainContainer .userProfileContentMainContainer .inputLable {
    color: #898989;
    margin: 10px 0px 10px 0px;
    font-size: 0.9rem;
}

.userPageMainContainer .userProfileContentMainContainer .input{
    border-width: 0px 0px 0px 1px;
    border-color: #ffffff;
    font-size: 0.9rem;
    padding-left: 3%;
}
.userProfileContentMainContainer select, .userProfileContentMainContainer textarea{
    border-width: 0px 0px 0px 1px;
    border-color: #BDBDBD;
    border-radius: 0%;
    font-size: 0.9rem;
    height: inherit;
}
.userProfileContentMainContainer textarea{
    padding-left: 3%;
    min-height: inherit;
}


.userPageMainContainer .userProfileContentMainContainer .inputHr {
    margin: 0px;
    margin-left: 3px;
    opacity: 0.3;
}

#dobError{
    display: none;
    font-size: 10px;
    color: #6e0101;
}

#emailVerifyTag{
    display: flex;
    font-size: 12px;
    color: #e61414;
}

.userPageMainContainer .topAlert{
    background: #FFE9E9;
    display: flex;
    padding: 4px 9px;
    border-radius: 6px;
    width: fit-content;
    align-items: baseline;
    height: fit-content;
    margin-left: 13px;
}

.userPageMainContainer .topAlert span{
    font-size: 14px;
}

.userPageMainContainer .topAlert img{
    width: 15px;
    height: fit-content;
    margin-left: 10px;
}

.userPageMainContainer .eyeIcon{
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.userPageMainContainer .eyeIconHide{
    visibility: hidden;
}

.userPageMainContainer .uploadIcon{
    width: 12px;
    cursor: pointer;
    margin-left: 6px;
    margin-top: -3px;
}

.userPageMainContainer .verifyDropDownArrowIcon{
    width: 22px;
    margin-right : 8px;
    cursor: pointer;    
}

.userPageMainContainer .thubmnailPopupContainer_inner {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
}



.ivr_status{
    margin-left: 10px;
    padding: 3px 6px;
    font-size: 0.75rem;
    border-radius: 5px;
    color: white;
}

.ivr_verified{
    background: #037b05;
}
.ivr_rejected{
    background: #5a0000;
}
.ivr_inProgress{
    background: #09778d;
}
.ivr_unVerified{
    background: #ffe9e9;
    color: black;
}

.accIcon{
    width: 12px;
}

@media (min-width: 992px) {
    .col-my-lg-1 {
        flex: 0 0 auto !important;
        width: 12% !important;
    }

    .col-contact-number-lg-9 {
        flex: 0 0 auto !important;
        width: 71.3% !important;
    }
}

.identityContent{
    color: #898989;
    font-size: 14px;
}
.identityContent>div{
 display: flex;
 padding: 5px 0px;
}
.identityContent select{
    border-radius: 4px;
    width: 155px;
    height: 36px;
    margin-left: auto;
    border: 1px solid #D3D4D8;
    color: #747474;
    font-size: 14px;
    text-align: center;
}
.identityContent button, #bssRequirements button{
    border-radius: 4px;
    width: 120px;
    height: 36px;
    margin-left: auto;
    border: 1px solid #D3D4D8;
    color: #747474;
    background-color: transparent;

}

#bssRequirements{
    color:  #545454;
    font-family: Inter;    
}

#bssRequirements hr{
    color:  #E0E0E0;  
    margin-top: 10px;  
    margin-bottom: 10px;
    /* border: 1px solid #E0E0E0; */
    border: transparent;
}

#bssRequirements>div{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
}

.label{
    font-family: Inter;
    font-size: 14px;
    font-weight: 100;
    line-height: 17px;
    /* letter-spacing: -0.03em; */
    text-align: left;
    color: #98979C;
    padding: 5px 0px;
}

#bssRequirements input[type = 'text']{
  border: 0px;
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
  font-size: 16px;
  padding-bottom: 10px;
}

#bssRequirements textarea{
 width: 100%;
 min-height: 78px;
 background-color: #F5F5F5;
 border: transparent;
 font-size: 16px;
}

.flex-40{
    width: 48%;
}
textarea:focus, input:focus{
    outline: none;
}

.required{
    color: red;
    font-size: 16px;
    font-weight: 900;
}
.dynamicHomePageMainContainer{
    padding: 15px 20px;
}

.dynamicHomePageMainContainer{
    padding: 15px 20px;
}

.dropDown, .inputDiv{
    width: 686px;
}

.course{
    width: 186px;
    height: 110px;
    box-shadow: 4px 5px 11px 0px #0000004D;
    background: #F1F1F1;
    border-radius: 10px;
    background-size: cover;
    background-position: center center;
}

.couponContainer{
    width: 314px;
    height: 367px;
    background: #F0F0F0;
    border-radius: 20px;
    overflow: hidden;
}
.couponImg{
    width: 100%;
    height: 314px;
    background-color: hotpink;
    padding: 10px;
    display: flex;
}
.couponCode{
    width: 100%;
    height: 35px;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin-top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropDown > span{
    color: #9F9F9F;
}

.selectListDynamicPage{
    margin-top: 300px;
    background-color: white;
    z-index: 10;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
}

.dropDownSearch{
    width: 300px;
}

.couponImg{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}
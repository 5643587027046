.htmlRendererDiv{
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #E8F9FF;
    font-family: Inter;
    font-size: 16px;
    padding: 7px 10px;
}

.courseReviews{
    margin-left: -20px;
    padding-left: 20px;
    border-top: 1px solid #e5e5e5;
}


/* Review css */
.review{
    display: flex;
    flex: 1;
    background-color: #ffffff;
    border: 0.5px solid #cccccc;
    box-shadow: 0px 0px 10px 0px #0000001A;
    border-radius: 3px;
    height: 195px;
    align-items: center;
    gap:10px;
    padding:15px;
}

.review .moveIcon{
    height: 7px;
}

.review .reviewUserImg{
    height: 56px;
    width: 56px;
    background-color: #e5e5e5;
    border-radius: 50%;
}
.review .reviewUserImgEdit{
    position: relative;
    top:20px;
    left: 20px;
    height:20px;
}

.review .reviewContent{
    display: flex;
    flex-direction:column;
    flex:1;
    height: 100%;
}
.review .reviewContent>div{
    flex:1;
    height:100%;
}


.review .reviewContent>div input{
    flex:1;
    width:100%;
    height:80%;
    border: 1px solid #CCCCCC;
}

.review .reviewIndex{
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    color:#9f9f9f;
}
.review .reviewUser{
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    color:#252525;
}
.addReviewIcon{
    height:20px;
}
.addReviewIcon:hover{
    cursor: pointer;
}
.addReviewIcon:active{
    transform: translateY(2px);
}

.user-image-container {
    /* position: relative; */
    display: inline-block;
    .image {
        width: 30px; /* Adjust as needed */
        height: auto; /* Adjust as needed */
        border: 3px solid white;
    }
    .image:hover + .username {
        display: block;
        cursor:'pointer';
    }

    .profileIcon:hover + .username {
        display: block;
        cursor: pointer;
    }
    .username {
        position: absolute;
        top: '100%'; /* Adjust as needed */
        left: '50%';
        border: 1px solid #BCBCBC;
        color: #FFAE4F;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
        background-color: #FFF4CD;

        display: none;
    }.username:hover {
        display: block;
    }
    
}

  
/* Reset default browser styles */
.main {
    font-family: Inter;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    
    margin: 0;
    padding: 0;
    border: 1px solid #e9e9e9;
    overflow-x: hidden;
    width: 100%;

    flex-grow: 1;
    font-size: 14px;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
  }

  /* Style the table */
  table {
    border-collapse: separate;
    width: 100%;
    background-color: #ffffff;
    border-spacing: 0;
  }

  th, td {
    border-right: 1px solid#e9e9e9;
    border-bottom: 1px solid#e9e9e9;
    padding: 10px;
    text-align: left;
    background-color: #ffffff;
  }


  tr{
    background-color: #ffffff;
    display: flex;
  }
  

  th {
    background-color: #ffffff;
    text-align: left;
    font-weight:600;
    box-shadow: 0px 4px 3px 0px #0000000D;
    border-bottom: 1px solid #e9e9e9;
    color: #252525;
  }

  tr:nth-child(even) {
    background-color: #ffffff;
  }

  /* tr:hover {
    background-color: #e9e9e9;
  } */

  th {
    position: relative;
    white-space: nowrap; /* Prevents text from wrapping */
  }

   td{
    white-space: nowrap;
    color: #252525;
    padding: 0px;
   }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #ffffff;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }
  
  .resizer:active, .resizer:hover {
    background-color: #1967D2;
    opacity: 1;
  }

  .tagDiv{
    display: flex;
    font-weight: 100;
    height: fit-content;
  }

  .autoTagDiv > *:not(:first-child) {
    /* Your styles here */
    margin-left: -18px;
  }
  
  .autoTagDiv > *:last-child {
    /* Your styles here */
   border-radius: 40px 40px 40px 40px;
   padding:5px 14px 5px 14px;
  }

  .autoTag{
   border-radius: 40px 10px 10px 40px;
   background-color: #1C92FF;
   padding:5px 20px 5px 14px;
   color: white;
   border: 3px solid #ffffff;
  }

  .manualTagDiv{
    margin-left: 10px;
  }

  .manualTag{
    margin-left: 5px;
    color: white;
    padding:5px 10px;
    border-radius: 4px;
    box-shadow:0px 0px 5px #ddd;
  }

  .Red{
    background-color: red;
  }

  .Green{
    background-color: #31BE00;
  }

  .tableBody{
    overflow-x: scroll;
    /* min-height: 80vh ; */
    height: 100px;
    flex-grow: 1;
    display:flex
  }
  .tableBody::-webkit-scrollbar{
    height: 7px;
  }

  .tableFooter{
    /* position: fixed; */
    /* bottom: 0px; */
    margin-top: auto;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: #4E4E4E;
    background-color: #ffffff;
    border-bottom: 3px solid#e9e9e9;
    width:100%;
    /* z-index: 999; */
  }

  .tableFooter button{
    background-color: transparent;
    border: none;
  }

  .tableFooter button:active {
    transform: translateY(2px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .tableFooter>button>img{
    width:auto;
    height:15px;
  }

  select{
    height: 36px;
    width: 78px;
    border: 1px solid #4E4E4E;
    border-radius: 4px;
    color: #4E4E4E;
  }

  .hiddenDiv {
    /* visibility: hidden; */
    opacity: 0;
    transition: visibility 0.3s ease, opacity 0.3s ease;
}
.hiddenDiv:hover {
  /* visibility: visible; */
  opacity: 1;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}
.hiddenDiv img{
  width: 20px;
  height: auto;
}

.clipboard{
  height: 13px;
  width:11px;
}
.eyeIcon{
  height: 7px;
  width: 15px;
  }

.clipboard:active, .eyeIcon:active{
    transform: translateY(2px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


/* https://codesandbox.io/p/devbox/github/tanstack/table/tree/main/examples/react/column-sizing?file=%2Fsrc%2Fmain.tsx%3A336%2C27-336%2C35 */

.showOnHover {
  padding: 10px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
/*
 * higher z-index brings element to front
 * auto width cancels the overflow
 */
 .showOnHover:hover {
  /* z-index: 1; */
  overflow: visible;
  width: fit-content;
  position: relative;
  background-color: #ffffff;
  min-width: 100%;
  border-right: 1px solid #e9e9e9;
}

.showOnHover:hover > * {
  /* vertical-align: middle;  
  overflow: visible; */
}

.sortIcon{
 height:10px;
 margin-left: 15px;
}

.columnOption{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px  #aaaaaa;
  width: 220px;
  height: 50%;
  overflow-y: scroll;
  position: absolute;
  top: 100px;
  left: 100px;
  display: none;
  z-index: 1000000;
  border-radius: 10px;
}

.columnOption>div{
  padding: 5px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap:15px;
}

.contentPageMainContainer{
    margin-left:240px;
}

.nav-tabs{
    border-bottom: 1px solid #e5e5e5;
}

.nav-tabs .nav-link{
    border: 0px;
    color: #252525;
}

.nav-tabs .nav-link.active {
    border-bottom: 4px solid #0E9AFF;
    ;
}

.contentPageMainContainer li{
    margin: 0px 20px;
}
.rightHandRightPageMainContainer .thumbnailContainer{
    width: 140px;
    height: 80px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumbnailContainer img{
    width: 24px;
    height: fit-content;
}
.filterDiv{
    width:100%;
    display:flex;
    align-items: center;
    gap: 1%;
    padding: 1% 0.5%;

}

#sortIcon{
    height: 22px;
}

#filter{
    width: 78px;
    position: absolute;
    right: 5%;
    display: flex;
    justify-content: space-between;
    padding-top:5px;
}

.button{
    width: 154px;
    height: 31px;
    border: 0.5px solid #B8B8B8;
    border-radius: 3px;
    color: #252525
}

.green{
    background: linear-gradient(0deg, #E8FFE2, #E8FFE2);
}

.red{
    background: linear-gradient(0deg, #FFE2E2, #FFE2E2);
}

.filters{
    position:absolute;
    top: 240px;
    right: 3%;
    display: flex;
    flex-direction: column;
    width: 123px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #888888;
    overflow: hidden;
    background-color: white;
    z-index: 100;
}

.filters button{
    background-color: white;
    border: none;
    padding: 2px;
}
.filters button:hover{
    background-color: rgb(168, 203, 231);
}
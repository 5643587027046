.filter {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.markXBtn {
    background: transparent;
    border: 0.5px solid #B8B8B8;
    border-radius: 3px;
    min-width: 248px;
    cursor: pointer;
}

.markXBtn img {
    width: 20px;
    margin-right: 5px;
}


.impressionContainer{
    font-family: Inter;
    color: #252525;
    font-weight: 500;
    text-align: left;
    
     .title{
        font-size: 18px;

     }

     .subTitle{
        font-size: 13px;
        color: #B4B4B4;


     }

     .trapaziod{
        flex-grow: 1;
        width: 100%;
        /* This clip-path is used to create a trapezoid shape for the impression container. 
       The parameters of the clip-path are the coordinates of the shape's vertices. 
       In this case, the shape is a trapezoid with the top and bottom edges being straight lines (0% and 100% of the width),
       and the left and right edges being angled lines (10% and 16% of the width).
       The top two numbers (0 and 0) are the coordinates of the first vertex,
       the next two numbers (100% and 0) are the coordinates of the second vertex,
       the next two numbers (10% and 41%) are the coordinates of the third vertex,
       and the last two numbers (16% and 41%) are the coordinates of the fourth vertex.
       The shape is closed by default, so the last vertex is not explicitly listed. */
       clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);

        /* width: 75%;
        height: 75%;
        margin-top: 10%; */

        /* transform-origin: center center;
        transform: perspective(50px) rotate3d(-1,0,0,3deg); */

        /* clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%); */
        /* box-shadow: 0 0 0 1px #000; */
        /* background-color: aliceblue; */

        .content{
            /* transform-origin: bottom center;
            transform: perspective(50px) rotate3d(1, 0, 0, 3deg); */
            text-align:center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-items: center;
            color: #6B6B6B;
            font-size: 12px;
            height:100%;
        }
     }
}
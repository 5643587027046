.queriesDetailsView{
    margin-left: 230px !important;
}
.queriesDetailsView .topHeading{
    margin-left: 20px;
}

.queriesDetailsView .contentContainer{
    padding-left: 10px;
}

/* START : style accordion  */
.queriesDetailsView .contentContainer .accordion-item{
    border-style: unset !important;
}
.queriesDetailsView .contentContainer .accordion-button{
    font-size: 16px;
    font-weight: 600;
    color: black;
    background-color: transparent !important;
    outline: unset !important;
    border: 0px !important;
    box-shadow: unset !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.accordion-button::focus{
    outline: unset I !important;
}
.accordion-button::after{
    background-size: 15px;
    margin-top: 8px;
    position: absolute;
    left: 0px;
}
.accordion-button:not(.collapsed)::after{
    margin-top: -2px;
    left: -5px;
}
/* END : style accordion  */

.queryCommentContainer{
    padding: 12px;
}
.queryCommentContainer .commentInput{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 1px solid #E5E5E5;
    margin-bottom: 15px;
    width: 300px;
    padding: 5px 10px;
}
.queryCommentContainer .btnContainer{
    display: flex;
    justify-content: flex-end;
}
.queryCommentContainer .saveBtn{
    border-radius: 8px;
    background: #252525;
    color: white;
    border: 0px;
    padding: 1px 25px;
}
.formFieldStatus{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;
    border: 1px solid;
    padding-left: 3px;
    padding-right: 3px;
    font-size: 12px;
    height: fit-content;
    width: fit-content;

    img{
        width:  14px;

    }
}
.selectInputDiv{
    width: 100%;
    font-family: Inter;
}

.selectInputDiv .selectInputHeader{
    /* font-family: IBM Plex Sans Thai Looped; */
    font-size: 17px;
    font-weight: 500;
    color:#252525
}

.selectInputDiv .selectInputInput{
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #686767;
    border: 1px solid #CCCCCC;
    height: calc(3.5rem + 2px);
    border-radius: 0.25rem;
    padding: 1rem 0.75rem;
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectInputDiv .selectInputInput:active{
    border: 1px solid #959595;
}

.selectInputDiv .selectInputInput img{
    height:7px;
}
.accountsPageMainContainer{
    margin: 0px 0px 0px 230px;
    padding-top: 20px;
    height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;
}

.accountsPageMainContainer>h4{
    margin-left: 20px;
}

 hr{
    margin-bottom: 0px;
    margin-top: 0px;
    border-top: 1px solid black;
}


.allComments_FilterContainer .sortContainer{
    background: white;
    position: absolute;
    width: 110px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 25px 0px #00000040;
}

.allComments_FilterContainer .sortContainer{
    margin-top: 130px;
}

.videoPage_CommentsPage_FilterContainer .sortContainer span,
.allComments_FilterContainer .sortContainer span{
    cursor: pointer;
    font-size: 14px;
}

.accountsPageMainContainer .allComments_FilterContainer{
    margin: 7px 5px 7px 14px;
    display: flex;
    align-items: center;
}


.allComments_FilterContainer .sortIcon{
    width: 25px;
    margin-right: 26px;
    cursor: pointer;
    height: fit-content;
}

.allComments_FilterContainer .inputDateContainer{
    margin-right: 20px;
}

.allComments_FilterContainer .inputDateContainer input[type="date"]{
    border-radius: 5px;
    border: 1px solid #000;
    padding: 5px;
}

.accountBannerContainer{
    display: flex;
    padding: 10px;
    gap:15px;
}

.banner{ 
    flex:0.5;
    display: flex;
    background-color: aqua;
    padding:20px 15px;
    border-radius: 6px;
    width: 686px;
    height: 148px;
    justify-content: center;
    align-items: center;
 }

 .gradientPurple{
    background: linear-gradient(90deg, #EB4482 0%, #B753A2 99.24%);
    box-shadow: 4px 5px 11px 0px #0000004D;

    min-width: 500px;
    color: white;
}

.gradientOrange{
    background: linear-gradient(21.29deg, #FBC248 0%, #F6854D 100%);
    box-shadow: 4px 5px 11px 0px #0000004D;

    min-width: 500px;
    color: white;
}


.bannerRightSide{
    flex:0.5;
    display: flex;
    gap:10%;
    justify-content: center;
    align-items: center;
    padding-right: 3%;
    padding-Left: 10%;

}

.bannerLeftSide{
    flex:0.5;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-Right: 10%;
    align-items: center;
    justify-content: center;
}

.bannerSeprator{
    background-color: white;
    width: 1px;
    height: 100%;
}
                        
.bold{
    font-weight: 600;
}


.fontLarge{
    font-size: 18px;
}
.fontExtraLarge{
    font-size: 25px;
}

.searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 8px 20px 8px 20px;
}

.searchBar {
    display: flex;
    padding: 2px;
    border: 1px solid black;
    border-radius: 10px;
    width: 50%;
    height: 40px;
  }
input[type="search"] {
    border: none;
    background: transparent;
    padding: 17px 0px;
    font-size: 14px;
    width:90%;
    
  }
input[type="search"]::placeholder {
    color: #888;
}
input[type="search"]:focus{
    outline: none;
}

button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }

button[type="submit"]:hover {
    opacity: 1;
}
button[type="submit"]:focus, input[type="search"]:focus {
    box-shadow: 0 0 3px 0 #1183d6;
    border-color: #1183d6;
    outline: none;
}

.deleteIcon{
    width: 20px;
    margin-right: 6px;
    cursor: pointer;
    height: fit-content;
}

.searchBarEnd{
    width: 50%;
    display: grid;
}

.excelIcon{
    width: 30px;
    margin-right: 26px;
    cursor: pointer;
    height: fit-content;
    justify-self: end;
    align-self: flex-end;
}

.filterMenu{
    position:absolute;
    top: 190px;
    left: 250px;
    display: flex;
    flex-direction: column;
    width: 123px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #888888;
    overflow: hidden;
    background-color: white;
    z-index: 100;
}

.filterMenu button{
    background-color: white;
    border: none;
    padding: 2px;
}
.filterMenu button:hover{
    background-color: rgb(168, 203, 231);
}

.hideScroll::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hideScroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
.leftMarginContainer{
    margin-top: 50px;
    margin-left: 530px;
}

.leftMarginContainer h3{
    position: relative;
    color: rgb(129, 0, 0);
    border: 1px solid rgb(129, 0, 0);
    width:fit-content;
    padding: 10px;
    box-shadow: 0px 0px 10px rgb(129, 0, 0);
    animation: popUpAnimation 2s infinite;
}

@keyframes popUpAnimation {
    0%, 100% {
      transform: translateY(0);
      box-shadow: 0px 0px 10px rgb(129, 0, 0);
    }
    50% {
      transform: translateY(40px);
      box-shadow: 0px 0px 10px rgb(27, 0, 0);
    }
  }
 